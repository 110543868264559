import SideNavCategory from "./shopBy/SideNavCategory";
import {
  readytowear,
  kanchi,
  partywearsarees,
  partyweargowns,
  unique,
  linen,
  kurti
} from "../../../constants";

const ShopSideNav = ({ selectedOption }) => {
  return (
    <div className="w-full flex flex-col gap-6">
      <SideNavCategory
        title="Ready To Wear Saree"
        items={readytowear}
        open={selectedOption === "Ready" ? true : false}
      />
      <SideNavCategory
        title="Kanchi Silk Saree"
        items={kanchi}
        open={selectedOption === "Kanchi" ? true : false}
      />
      <SideNavCategory
        title="Party Wear Saree"
        items={partywearsarees}
        open={selectedOption === "PartySaree" ? true : false}
      />
      <SideNavCategory
        title="Party Wear Gowns"
        items={partyweargowns}
        open={selectedOption === "PartyGowns" ? true : false}
      />
      <SideNavCategory
        title="Unique Saree"
        items={unique}
        open={selectedOption === "Unique" ? true : false}
      />
      <SideNavCategory
        title="Original Linen Saree"
        items={linen}
        open={selectedOption === "Linen" ? true : false}
      />
      <SideNavCategory
        title="Pure Cotton Princess Cut Kurti"
        items={kurti}
        open={selectedOption === "Kurti" ? true : false}
      />
    </div>
  );
};

export default ShopSideNav;
