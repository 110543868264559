import React from "react";
import Slider from "react-slick";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
// import {
//   newArrOne,
//   newArrTwo,
//   newArrThree,
//   newArrFour,
//   newArrFive,
// } from "../../../assets/images/index";
import SampleNextArrow from "./SampleNextArrow";
import SamplePrevArrow from "./SamplePrevArrow";

const NewArrivals = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <div className="w-full pb-16">
      <Heading heading="New Arrivals" />
      <Slider {...settings}>
        <div className="px-2">
          <Product
            _id="100001"
            img="https://storage.googleapis.com/clothing-jewelry/images/products/newArrival/newArrOne.jpg"
            productName="Netted soft satin silk"
            price="44.00"
            des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
            onHome="true"
          />
        </div>
        <div className="px-2">
          <Product
            _id="100002"
            img="https://storage.googleapis.com/clothing-jewelry/images/products/newArrival/newArrTwo.jpg"
            productName="Pure soft muslin silk saree"
            price="250.00"
            des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
            onHome="true"
          />
        </div>
        <div className="px-2">
          <Product
            _id="100003"
            img="https://storage.googleapis.com/clothing-jewelry/images/products/newArrival/newArrThree.jpg"
            productName="Mirror georgette saree"
            price="80.00"
            des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
            onHome="true"
          />
        </div>
        <div className="px-2">
          <Product
            _id="100004"
            img="https://storage.googleapis.com/clothing-jewelry/images/products/newArrival/newArrFour.jpg"
            productName="Refreshing blue flora"
            price="60.00"
            des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
            onHome="true"
          />
        </div>
        <div className="px-2">
          <Product
            _id="100005"
            img="https://storage.googleapis.com/clothing-jewelry/images/products/newArrival/newArrFive.jpg"
            productName="Purple litchi silk"
            price="60.00"
            des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
            onHome="true"
          />
        </div>
      </Slider>
    </div>
  );
};

export default NewArrivals;
