import React, { useState } from "react";
import Slider from "react-slick";
// import {
//   bannerImgOne,
//   bannerImgTwo,
//   bannerImgThree,
// } from "../../assets/images";
import Image from "../designLayouts/Image";
import ShopNow from "../designLayouts/buttons/ShopNow";

const Banner = () => {
  const [dotActive, setDocActive] = useState(0);
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (prev, next) => {
      setDocActive(next);
    },
    appendDots: (dots) => (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "3%",
          transform: "translateY(-50%)",
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={
          i === dotActive
            ? {
                width: "30px",
                color: "#262626",
                borderRight: "3px #262626 solid",
                padding: "8px 0",
                cursor: "pointer",
              }
            : {
                width: "30px",
                color: "transparent",
                borderRight: "3px white solid",
                padding: "8px 0",
                cursor: "pointer",
              }
        }
      >
        0{i + 1}
      </div>
    ),
    responsive: [
      {
        breakpoint: 576,
        settings: {
          dots: true,
          appendDots: (dots) => (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "2%",
                transform: "translateY(-50%)",
              }}
            >
              <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
          ),
          customPaging: (i) => (
            <div
              style={
                i === dotActive
                  ? {
                      width: "25px",
                      color: "#262626",
                      borderRight: "3px #262626 solid",
                      cursor: "pointer",
                      fontSize: "12px",
                    }
                  : {
                      width: "25px",
                      color: "transparent",
                      borderRight: "3px white solid",
                      cursor: "pointer",
                      fontSize: "12px",
                    }
              }
            >
              0{i + 1}
            </div>
          ),
        },
      },
    ],
  };
  return (
    <div className="w-full h-full bg-white">
      <Slider {...settings}>
        <div className="w-full h-80 bg-[#f3f3f3] md:bg-transparent relative font-titleFont">
          <Image
            className="w-full h-full object-fill hidden md:inline-block"
            imgSrc="https://storage.googleapis.com/clothing-jewelry/images/banner/bannerImgOne.jpg"
          />
          <div className="w-full md:w-2/3 xl:w-1/2 h-80 absolute px-4 md:px-0 top-0 right-0 flex flex-col items-start gap-6 justify-center">
            <h1 className="text-2xl md:text-3xl font-semibold text-white">
              Sarees that Exude Elegance
            </h1>
            <p className="text-xs md:text-base font-normal text-white max-w-md md:max-w-[600px]">
              Discover a mesmerizing collection of sarees that exude sheer
              elegance and charm. Each saree is meticulously crafted with
              intricate details and luxurious fabrics, showcasing the epitome of
              traditional Indian craftsmanship. Elevate your style and embrace
              timeless grace with our handpicked sarees that are perfect for
              every occasion.
            </p>
            <ShopNow />
          </div>
        </div>
        <div className="w-full h-80 bg-[#f3f3f3] md:bg-transparent relative font-titleFont">
          <Image
            className="w-full h-full object-fill hidden md:inline-block"
            imgSrc="https://storage.googleapis.com/clothing-jewelry/images/banner/bannerImgTwo.jpg"
          />
          <div className="w-full md:w-2/3 xl:w-1/2 h-80 absolute px-4 md:px-0 top-0 right-0 flex flex-col items-start gap-6 justify-center">
            <h1 className="text-2xl md:text-3xl font-semibold text-white">
              Embrace the Timeless Beauty
            </h1>
            <p className="text-xs md:text-base font-normal text-white max-w-md md:max-w-[600px]">
              Immerse yourself in the enchanting world of timeless beauty with
              our stunning selection of sarees. Each piece is a work of art,
              reflecting the rich cultural heritage of India. Delight in the
              intricate patterns, vibrant colors, and delicate embellishments
              that adorn our sarees. Let the allure of tradition and grace
              envelop you as you drape yourself in the finest fabrics that
              celebrate the essence of womanhood.
            </p>
            <ShopNow />
          </div>
        </div>
        <div className="w-full h-80 bg-[#f3f3f3] md:bg-transparent relative font-titleFont">
          <Image
            className="w-full h-full object-fill hidden md:inline-block"
            imgSrc="https://storage.googleapis.com/clothing-jewelry/images/banner/bannerImgThree.jpg"
          />
          <div className="w-full md:w-2/3 xl:w-1/2 h-80 absolute px-4 md:px-0 top-0 right-0 flex flex-col items-start gap-6 justify-center">
            <h1 className="text-2xl md:text-3xl font-semibold text-white">
              Unravel the Charm of Sarees
            </h1>
            <p className="text-xs md:text-base font-normal text-white max-w-md md:max-w-[600px]">
              Unravel the enchanting charm of sarees that tell stories of
              tradition, heritage, and elegance. Our exclusive collection boasts
              a kaleidoscope of colors and designs, ranging from classic to
              contemporary styles. With every drape, experience the timeless
              allure that only sarees can bring, making you feel confident and
              captivating in every step you take.
            </p>
            <ShopNow />
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Banner;
