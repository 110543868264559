import React, { useState } from "react";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import PageItems from "../../components/pageProps/shopPage/PageItems";
import ProductBanner from "../../components/pageProps/shopPage/ProductBanner";
import ShopSideNav from "../../components/pageProps/shopPage/ShopSideNav";

const Shop = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const selectionHandler = (selection) => {
    setSelectedOption(selection);
  };

  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Our Products" />
      {/* ================= Products Start here =================== */}
      <div className="w-full h-full flex pb-20 gap-10">
        <div className="w-[20%] lgl:w-[25%] hidden mdl:inline-flex h-full">
          <ShopSideNav selectedOption={selectedOption}/>
        </div>
        <div className="w-full mdl:w-[80%] lgl:w-[75%] h-full flex flex-col gap-10">
          <ProductBanner onSelection={selectionHandler} />
          <PageItems selectedOption={selectedOption} />
        </div>
      </div>
      {/* ================= Products End here ===================== */}
    </div>
  );
};
  
export default Shop;
