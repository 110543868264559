import React, { useState } from "react";
import { motion } from "framer-motion";
import NavTitle from "./NavTitle";
import { useEffect } from "react";

const SideNavCategory = (props) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(props.open);
  }, [props.open]);

  return (
    <div>
      <div onClick={() => setShow(!show)} className="cursor-pointer">
        <NavTitle title={props.title} />
      </div>
      {show && (
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <ul className="flex flex-col gap-4 text-sm lg:text-base text-[#767676]">
            {props.items.map((item) => (
              <li
                key={item._id}
                className="border-b-[1px] border-b-[#F0F0F0] pb-2 flex items-center gap-2"
              >
                {item.productName}
              </li>
            ))}
          </ul>
        </motion.div>
      )}
    </div>
  );
};

export default SideNavCategory;
