import Product from "../../home/Products/Product";
import {
  readytowear,
  kanchi,
  partywearsarees,
  partyweargowns,
  unique,
  linen,
  kurti,
} from "../../../constants/index";

function Items({ currentItems }) {
  return (
    <>
      {currentItems &&
        currentItems.map((item) => (
          <div key={item._id} className="w-full">
            <Product
              _id={item._id}
              img={item.img}
              productName={item.productName}
              price={item.price}
              des={item.des}
            />
          </div>
        ))}
    </>
  );
}

const PageItems = ({ selectedOption }) => {
  let currentSelectedItems = [];
  switch (selectedOption) {
    case "Ready":
      currentSelectedItems = readytowear;
      break;
    case "Kanchi":
      currentSelectedItems = kanchi;
      break;
    case "PartySaree":
      currentSelectedItems = partywearsarees;
      break;
    case "PartyGowns":
      currentSelectedItems = partyweargowns;
      break;
    case "Unique":
      currentSelectedItems = unique;
      break;
    case "Linen":
      currentSelectedItems = linen;
      break;
    case "Kurti":
      currentSelectedItems = kurti;
      break;
    default:
      currentSelectedItems = readytowear;
      break;
  }
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 mdl:gap-4 lg:gap-10">
        <Items currentItems={currentSelectedItems} />
      </div>
    </div>
  );
};

export default PageItems;
