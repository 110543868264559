import { GoTriangleDown } from "react-icons/go";

const ProductBanner = (props) => {
  return (
    <div className="w-full flex flex-row justify-center">
      <div className="flex items-center gap-2 text-base text-[#767676] relative">
        <label className="block" htmlFor="types">
          Select:
        </label>
        <select
          onChange={(e) => {
            props.onSelection(e.target.value);
          }}
          id="types"
          className="w-52 border-[1px] border-gray-200 py-1 px-4 cursor-pointer text-primeColor text-base block dark:placeholder-gray-400 appearance-none focus-within:outline-none focus-visible:border-primeColor"
        >
          <option value="Ready">Ready To Wear Saree</option>
          <option value="Kanchi">Kanchi Silk Saree</option>
          <option value="PartySaree">Party Wear Saree</option>
          <option value="PartyGowns">Party Wear Gowns</option>
          <option value="Unique">Unique Saree</option>
          <option value="Linen">Original Linen Saree</option>
          <option value="Kurti">Pure Cotton Princess Cut Kurti</option>
        </select>
        <span className="absolute text-sm right-2 md:right-4 top-2.5">
          <GoTriangleDown />
        </span>
      </div>
    </div>
  );
};

export default ProductBanner;
