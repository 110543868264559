import React from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
// import {
//   bestSellerOne,
//   bestSellerTwo,
//   bestSellerThree,
//   bestSellerFour,
// } from "../../../assets/images/index";

const BestSellers = () => {
  return (
    <div className="w-full pb-20">
      <Heading heading="Our Bestsellers" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-4 gap-10">
        <Product
          _id="1011"
          img="https://storage.googleapis.com/clothing-jewelry/images/products/bestSeller/bestSellerOne.jpg"
          productName="Leopard gown"
          price="35.00"
          des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
          onHome="true"
        />
        <Product
          _id="1012"
          img="https://storage.googleapis.com/clothing-jewelry/images/products/bestSeller/bestSellerTwo.jpg"
          productName="Refreshing light blue"
          price="180.00"
          des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
          onHome="true"
        />
        <Product
          _id="1013"
          img="https://storage.googleapis.com/clothing-jewelry/images/products/bestSeller/bestSellerThree.jpg"
          productName="Sunflower saree"
          price="25.00"
          des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
          onHome="true"
        />
        <Product
          _id="1014"
          img="https://storage.googleapis.com/clothing-jewelry/images/products/bestSeller/bestSellerFour.jpg"
          productName="princess cut kurti"
          price="220.00"
          des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
          onHome="true"
        />
      </div>
    </div>
  );
};

export default BestSellers;
