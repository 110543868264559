import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import ReviewSlide from "./ReviewSlide";
import Slider from "react-slick";
import SampleNextArrow from "../../components/home/NewArrivals/SampleNextArrow";
import SamplePrevArrow from "../../components/home/NewArrivals/SamplePrevArrow";

const Reviews = () => {
  const settings = {
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    setPrevLocation(location.state.data);
  }, [location]);
  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Saree Stories" prevLocation={prevLocation} />

      <div className="container px-0">
        <Slider {...settings}>
          <div className="bg-gray-100 p-4 rounded-lg shadow-md">
            <ReviewSlide
              name="Neha Sharma"
              role="Fashion Blogger"
              content="I recently purchased a saree from this shop, and I couldn't be happier with my choice. The quality and design of the saree were outstanding. I received numerous compliments at the event, and I highly recommend this shop to all saree enthusiasts."
            />
          </div>
          <div className="bg-gray-100 p-4 rounded-lg shadow-md">
            <ReviewSlide
              name="Rajni Patel"
              role="Marketing Executive"
              content="I have been a loyal customer of this saree shop for several years now, and they never disappoint. Their saree collection is diverse, and the staff is always helpful and courteous. I always find the perfect saree for every occasion, and the prices are reasonable too."
            />
          </div>
          <div className="bg-gray-100 p-4 rounded-lg shadow-md">
            <ReviewSlide
              name="Anjali Singh"
              role="Software Engineer"
              content="Shopping for sarees at this store is a delight! They have an amazing selection, and I love the personalized service they provide. The staff patiently helped me choose the right saree for my sister's wedding, and it was a hit! I will definitely be coming back for more sarees in the future."
            />
          </div>
        </Slider>
      </div>

      <div className="flex justify-center items-center my-8">
        <Link to="/shop">
          <button className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-100">
            Continue Shopping
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Reviews;
