import React from "react";
import { FaQuoteLeft } from "react-icons/fa";
import { FaQuoteRight } from "react-icons/fa";

const ReviewSlide = ({ name, role, content }) => {
  return (
    <div className="box">
      <div className="flex flex-col items-center">
        <h5 className="text-4xl font-bodyFont font-semibold">{name}</h5>
        <h6 className="text-xl font-mono mb-8">{role}</h6>
        <FaQuoteLeft className="self-start " />
        <p className="text-center text-lg">{content}</p>
        <FaQuoteRight className="self-end" />
      </div>
    </div>
  );
};

export default ReviewSlide;
