import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { ReactComponent as Shipping } from "../../assets/images/truck.svg";
import { ReactComponent as Free } from "../../assets/images/free.svg";
import { ReactComponent as Quality } from "../../assets/images/high-quality.svg";

const WhyUs = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    setPrevLocation(location.state.data);
  }, [location]);
  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Why Shop With Us" prevLocation={prevLocation} />

      <section>
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="box bg-gray-200">
              <div className="w-full h-48 md:w-full md:h-48 flex items-center justify-center">
                <Shipping width="150" height="150" />
              </div>
              <div className="detail-box flex flex-col">
                <h5 className="text-xl font-semibold self-center">
                  Fast Delivery
                </h5>
                <p className="mt-2 text-center">
                  Receive your desired sarees at your doorstep in no time,
                  ensuring you're always ready for that special occasion.
                </p>
              </div>
            </div>
            <div className="box bg-gray-200">
              <div className="w-full h-48 md:w-full md:h-48 flex items-center justify-center">
                <Free width="150" height="150" />
              </div>
              <div className="detail-box flex flex-col">
                <h5 className="text-xl font-semibold self-center">
                  Free Shipping
                </h5>
                <p className="mt-2 text-center">
                  Enjoy the luxury of free shipping on all orders, making your
                  saree purchase even more delightful.
                </p>
              </div>
            </div>
            <div className="box bg-gray-200">
              <div className="w-full h-48 md:w-full md:h-48 flex items-center justify-center">
                <Quality width="150" height="150" />
              </div>
              <div className="detail-box flex flex-col">
                <h5 className="text-xl font-semibold self-center">
                  Best Quality
                </h5>
                <p className="mt-2 text-center">
                  Rest assured that each saree is crafted with the utmost care
                  and precision, guaranteeing the finest quality and timeless
                  elegance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="flex justify-center items-center my-8">
        <Link to="/shop">
          <button className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300">
            Continue Shopping
          </button>
        </Link>
      </div>
    </div>
  );
};

export default WhyUs;
