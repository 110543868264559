// import {
//   readytowear1,
//   readytowear2,
//   readytowear3,
//   readytowear4,
//   readytowear5,
//   partywearsarees1,
//   partywearsarees2,
//   partywearsarees3,
//   partywearsarees4,
//   partywearsarees5,
//   partyweargowns1,
//   partyweargowns2,
//   partyweargowns3,
//   partyweargowns4,
//   partyweargowns5,
//   unique1,
//   unique2,
//   unique3,
//   unique4,
//   kanchi1,
//   kanchi2,
//   kanchi3,
//   kanchi4,
//   kanchi5,
// } from "../assets/images/index";

// =================== NavBarList Start here ====================
export const navBarList = [
  {
    _id: 1001,
    title: "Home",
    link: "/",
  },
  {
    _id: 1002,
    title: "Shop",
    link: "/shop",
  },
  {
    _id: 1003,
    title: "Why Us",
    link: "/why-us",
  },
  {
    _id: 1004,
    title: "Contact Us",
    link: "contact-us",
  },
  {
    _id: 1005,
    title: "Saree Stories",
    link: "/reviews",
  },
];
// =================== NavBarList End here ======================

// =================== Product Lists Start here =================

export const readytowear = [
  {
    _id: "101",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/readytowear/readytowear1.jpg",
    productName: "Prism Organza silk",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "102",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/readytowear/readytowear2.jpg",
    productName: "Black designer georgette",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "103",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/readytowear/readytowear3.jpg",
    productName: "Chinon",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "104",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/readytowear/readytowear4.jpg",
    productName: "Gold zari dual color georgette",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "105",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/readytowear/readytowear5.jpg",
    productName: "Multicolor georgette",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
];

export const kanchi = [
  {
    _id: "106",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/kanchi/kanchi1.jpg",
    productName: "Batik print",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "107",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/kanchi/kanchi2.jpg",
    productName: "Elegant cream",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "108",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/kanchi/kanchi3.jpg",
    productName: "Fancy silk zari weaving",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "109",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/kanchi/kanchi4.jpg",
    productName: "Feather soft",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "110",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/kanchi/kanchi5.jpg",
    productName: "Pure soft muslin",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
];

export const partywearsarees = [
  {
    _id: "111",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/partywearsarees/partywearsarees1.jpg",
    productName: "Dazzling",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "112",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/partywearsarees/partywearsarees2.jpg",
    productName: "Georgette",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "113",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/partywearsarees/partywearsarees3.jpg",
    productName: "Mirror georgette",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "114",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/partywearsarees/partywearsarees4.jpg",
    productName: "Dual color tone",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "115",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/partywearsarees/partywearsarees5.jpg",
    productName: "Full mirror embroidery",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
];

export const partyweargowns = [
  {
    _id: "116",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/partyweargowns/partyweargowns1.jpg",
    productName: "Georgette gown with organza jacket",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "117",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/partyweargowns/partyweargowns2.jpg",
    productName: "Leopard gown",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "118",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/partyweargowns/partyweargowns3.jpg",
    productName: "Pink embroidery net",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "119",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/partyweargowns/partyweargowns4.jpg",
    productName: "Premium designer salwar set",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "120",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/partyweargowns/partyweargowns5.jpg",
    productName: "Refreshing blue floral",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
];

export const unique = [
  {
    _id: "121",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/unique/unique1.jpg",
    productName: "Alia Rocky aur rani",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "122",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/unique/unique2.jpg",
    productName: "Red pink alia",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "123",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/unique/unique3.jpg",
    productName: "Sky blue Katrina",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "124",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/unique/unique4.jpg",
    productName: "Sunflower",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
];

export const linen = [
  {
    _id: "125",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/linen/LS001.jpg",
    productName: "Original linen saree 1",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "126",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/linen/LS002.jpg",
    productName: "Original linen saree 2",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "127",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/linen/LS003.jpg",
    productName: "Original linen saree 3",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "128",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/linen/LS004.jpg",
    productName: "Original linen saree 4",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "129",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/linen/LS005.jpg",
    productName: "Original linen saree 5",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
];

export const kurti = [
  {
    _id: "130",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/kurti/CK001.jpg",
    productName: "Pure cotton princess cut kurti 1",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "131",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/kurti/CK002.jpg",
    productName: "Pure cotton princess cut kurti 2",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "132",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/kurti/CK003.jpg",
    productName: "Pure cotton princess cut kurti 3",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "133",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/kurti/CK004.jpg",
    productName: "Pure cotton princess cut kurti 4",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "134",
    img: "https://storage.googleapis.com/clothing-jewelry/images/products/kurti/CK005.jpg",
    productName: "Pure cotton princess cut kurti 5",
    price: "35.00",
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
];

// =================== Product Lists End here ===================
